@import '@lualtek/react-components/dist/core/utils/media.css';

.ChatPanel {
  overflow: hidden;
  height: 100dvh;

  @media (--from-extra-large) {
    height: 100%;
  }

  & > div {
    height: 100%;
    padding-top: 0;
  }
}


div:has(> .ChatPanel) {
  position: sticky;
  top: var(--shell-gap);
  block-size: calc(100vh - var(--shell-gap) * 2);
  block-size: calc(100dvh - var(--shell-gap) * 2);
}
